import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from "axios"
import VueSimpleAlert from "vue-simple-alert";
import SimpleVueValidation from 'simple-vue-validator'
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuex)
Vue.use(IconsPlugin)
Vue.use(CoreuiVue)
Vue.use(BootstrapVue)
Vue.use(SimpleVueValidation)
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false;

import store from './store/index'

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 422) {

      store.commit("setErrors", error.response.data.error);
      if (error.response.data.error.email == 'The email has already been taken.') {
        localStorage.setItem("errorMessage", error.response.data.error.email)
        setTimeout(function () {
          localStorage.removeItem("errorMessage")
        }, 2000);
      } else {
        var null11 = '';
        localStorage.setItem("errorMessage", null11)
      }
      if (error.response.data.error.password == 'The password format is invalid.') {
        localStorage.setItem("errorMessagePassword", 'The password format is invalid. (Capital letter,Special Character (@#$), numerical )')
        setTimeout(function () {
          localStorage.removeItem("errorMessagePassword")
        }, 2000);

      } else {
        var null12 = '';
        localStorage.setItem("errorMessagePassword", null12)
      }
      if (error.response.data.error == 'Email is not verified') {
        localStorage.setItem("errorMessageVerified", 'Email is not verified')
        setTimeout(function () {
          localStorage.removeItem("errorMessageVerified")
        }, 2000);

      }else  if (error.response.data.error == 'Already login in other device') {
        localStorage.setItem("errorMessageAlreadylogin", 'Already login in other device')
        setTimeout(function () {
          localStorage.removeItem("errorMessageAlreadylogin")
        }, 2000);

      } else {
        var null112 = '';
        localStorage.setItem("errorMessageVerified", null112)
      }

    } else if (error.response.status === 401) {
      store.commit("setUserData", null);
      localStorage.removeItem("token");
      router.push({ name: "Login" });
    } else {
      return Promise.reject(error);
    }
  }
);
//right click 

window.addEventListener('contextmenu', function (e) {
  document.body.innerHTML 
  e.preventDefault();
}, false);

// document.onkeydown = function (e) {

//   // disable F12 key
//   if(e.keyCode == 123) {
//       return false;
//   }

//   // disable I key
//   if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
//       return false;
//   }

//   // disable J key
//   if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
//       return false;
//   }

//   // disable U key
//   if(e.ctrlKey && e.keyCode == 85) {
//       return false;
//   }
// }
axios.interceptors.request.use(function (config) {
  config.headers.common['Access-Control-Allow-Origin'] = '*'
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  return config;
});
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
new Vue({
  el: '#app',
  router,
  store,
  icons, BootstrapVue, IconsPlugin,
  template: '<App/>',
  components: {
    App
  }

})
